<template>
  <div>
    <div class="title">{{$t('titleI18n.BaseInfo')}}</div>
    <el-form ref="formInfo" :model="formInfo" label-width="210px">
      <el-form-item :label="$t('formTitleI18n.BusinessType')" prop="businessType">
        <span>{{ formInfo.businessType | filterBusinessType }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.CoreUserName')" prop="coreUserId">
        <span>{{ formInfo.payerName }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.OrderAmount')" prop="orderAmount">
        <span>{{ ((Number(formInfo.amount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}</span>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.OrderTime')" prop="orderTime">
        <span>{{ formInfo.dueDate | timeStampToDate }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ProductType')" prop="productType">
        <span>{{ formInfo.desctiption }}</span>
      </el-form-item>

      <div class="title">{{$t('titleI18n.InvoiceInfo')}}</div>
      <el-form-item :label="$t('formTitleI18n.InvoiceUpload')" prop="invoiceUrl" style="width: 100%;">
          <template v-if="formInfo.invoice">
              <div v-for="(item,index) in JSON.parse(formInfo.invoice)" :key="index">
                  <el-link
                          class="link-style"
                          :underline="false"
                          type="primary"
                          @click="goLink(item)"
                  >{{ item.split("/")[item.split("/").length - 1] }}</el-link>
              </div>
          </template>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.InvoiceNum')" prop="invoiceNum">
        <span>{{ formInfo.invoiceCode }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.InvoiceAmount')" prop="invoiceAmount">
        <span>{{ ((Number(formInfo.invoiceValue || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}</span>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>

      <div class="title" v-if="formInfo.contractNumber || formInfo.contractName || formInfo.contractAmount || formInfo.contractValid">{{$t('titleI18n.ContractInfo')}}</div>
      <el-form-item :label="$t('formTitleI18n.ContractNum')" prop="contractNumber">
        <span>{{ formInfo.contractNumber || "&nbsp;" }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractName')" prop="contractName">
        <span>{{ formInfo.contractName || "&nbsp;" }}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractAmount')" prop="contractAmount" v-if="formInfo.contractAmount">
        <span>{{ ((Number(formInfo.contractAmount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}</span>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item :label="$t('formTitleI18n.ContractTime')" prop="contractValid"
                    v-if="formInfo.contractValid">
        <span>{{ formInfo.contractValid | timeStampToDate }}</span>
      </el-form-item>

      <div class="title">{{$t('titleI18n.RepayType')}}</div>
      <el-form-item :label="$t('formTitleI18n.RepayType')" prop="contractPaymentMode">
        <span>{{ formInfo.contractPaymentMode | filterRepayType }}</span>
      </el-form-item>

      <div class="title">{{$t('titleI18n.TicketInfo')}}</div>
      <el-form-item :label="$t('formTitleI18n.TicketUpload')" prop="contractDetail" style="width: 100%;">
          <template v-if="formInfo.contractDetail">
              <div v-for="(item,index) in JSON.parse(formInfo.contractDetail)" :key="index">
                  <el-link
                          class="link-style"
                          :underline="false"
                          type="primary"
                          @click="goLink(item)"
                  >{{ item.split("/")[item.split("/").length - 1] }}</el-link>
              </div>
          </template>

      </el-form-item>
    </el-form>
    <div class="flex-row-center">
      <el-button class="btn-black" @click="toList">{{$t('btnI18n.Close')}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "assets-info",
  data() {
    return {
      formInfo: {
        businessType: "",
        contractAmount: "",
        contractName: "",
        contractNum: "",
        contractTime: "",
        coreUserId: "",
        invoiceAmount: "",
        invoiceNum: "",
        invoiceUrl: "",
        orderAmount: "",
        orderTime: "",
        productType: "",
        repayType: "",
        ticketUrl: []
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    goLink(url) {
      if (/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(url)) {
        const image = new Image();
        image.src = url;
        const imgWindow = window.open(url);
        imgWindow.document.write(image.outerHTML);
      } else if (/.*(\.pdf|\.PDF)$/.test(url)) {
          let routeData = this.$router.resolve({
              query: {url:url},
              path:'/reviewPDF'
          });
          window.open(routeData.href, '_blank');
      } else {
        window.open(url);
      }
    },
    getDetail() {
      let _this = this;
      if (!this.$route.query.id) return false;
      this.$axios.get("/manage-supplier/receivable-detail", { params: {id: this.$route.query.id} }).then(result => {
        if (!result.code) {
          _this.formInfo = result.data;
        }
      });
    },
    toList() {
      this.$router.push({ path: "/assets-list" });
    },
  }
};
</script>

<style scoped lang="scss">
.el-form-item {
  width: 550px;
  display: inline-block;
}
.check-example {
  margin-left: 15px;
}
</style>
